.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
  }
  
  .form-label-group {
    position: relative;
    margin-bottom: 0.75rem;
  }
  
  .form-label-group > label {
    height: 2.75rem;
    padding: .7rem 1rem;
  }
  
  .form-label-group > input{
    height: 2.75rem;
    padding: 1rem .7rem;
    color: black;
  }
  
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.25;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .15rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:placeholder-shown), .form-label-group input:focus {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
    color: black;
  }
  
  .form-label-group input:not(:placeholder-shown) ~ label, .form-label-group input:focus ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }
  
  /* Fallback for Edge
  -------------------------------------------------- */
  @supports (-ms-ime-align: auto) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777;
    }
  }
  
  /* Fallback for IE
  -------------------------------------------------- */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777;
    }
  }